
/* poppins-300 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/poppins-v20-latin_latin-ext-300.woff2') format('woff2'),
         url('../fonts/poppins-v20-latin_latin-ext-300.ttf') format('truetype');
}
/* poppins-regular - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v20-latin_latin-ext-regular.woff2') format('woff2'),
         url('../fonts/poppins-v20-latin_latin-ext-regular.ttf') format('truetype');
}
/* poppins-500 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v20-latin_latin-ext-500.woff2') format('woff2'),
         url('../fonts/poppins-v20-latin_latin-ext-500.ttf') format('truetype');
}
/* poppins-600 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v20-latin_latin-ext-600.woff2') format('woff2'),
         url('../fonts/poppins-v20-latin_latin-ext-600.ttf') format('truetype');
}
/* poppins-700 - latin_latin-ext */
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/poppins-v20-latin_latin-ext-700.woff2') format('woff2'),
         url('../fonts/poppins-v20-latin_latin-ext-700.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind screens;
@tailwind utilities;


html, body {
    @apply font-poppins font-normal text-base;
}


/* buttons */
.btn {
    @apply inline-flex justify-center items-center gap-3 min-h-14 text-base font-semibold px-9 py-3 border rounded-lg transition-all duration-300;
    @apply hover:border-black;
    @apply focus:border-black;

    svg * {
        @apply transition-all duration-300;
    }

    &.btn-small {
        @apply gap-2 font-light text-sm min-h-10 px-3.5 py-2.5;
    }
}
.btn-primary {
    @apply text-black bg-transparent border-black;
    @apply hover:text-white hover:bg-black hover:border-black;
    @apply focus:text-white focus:bg-black focus:border-black;

    &:hover svg * {
        @apply fill-white;
    }

    &:focus svg * {
        @apply fill-white;
    }
}
.btn-primary-white {
    @apply text-white bg-transparent border-white;
    @apply hover:text-white hover:bg-black hover:border-black;
    @apply focus:text-white focus:bg-black focus:border-black;

    svg * {
        @apply !fill-white;
    }
}
.btn-third {
    @apply text-black bg-white border-white;
    @apply hover:text-white hover:bg-black hover:border-black;
    @apply focus:text-white focus:bg-black focus:border-black;

    &:hover svg * {
        @apply fill-white;
    }

    &:focus svg * {
        @apply fill-white;
    }
}
.btn-submit {
    @apply text-black bg-white border-white;
    @apply hover:text-white hover:bg-black hover:border-black;
    @apply focus:text-white focus:bg-black focus:border-black;

    &:hover svg * {
        @apply fill-white;
    }

    &:focus svg * {
        @apply fill-white;
    }
}



/* forms */
label {
    @apply block font-medium text-sm text-zinc-800 mb-3;
    @apply mobile:text-base;
}
input,
textarea {
    @apply font-light text-sm placeholder-gray-400;
    @apply focus:placeholder-transparent;
    @apply mobile:text-base;
}
textarea {
    @apply w-full max-w-full rounded-lg p-4;
}
[type="text"] {
    @apply w-full max-w-full min-h-10 px-4 py-1 border rounded-lg text-zinc-800 border-zinc-400 bg-transparent leading-4;
    @apply hover:border-black hover:ring-0 hover:shadow-none;
    @apply focus:border-black focus:ring-0 focus:shadow-none focus:outline-none;
    @apply mobile:min-h-10.75;
}
.checkbox-btn {
    @apply font-light text-sm inline-flex justify-center items-center min-w-11 min-h-10 mb-0 px-3 py-2 border rounded-lg text-zinc-800 border-zinc-400 leading-5 transition-all duration-500 cursor-pointer;
    @apply hover:bg-zinc-100;
    @apply focus:bg-zinc-100;
    @apply mobile:min-h-10.75 mobile:text-base;

    input {
        @apply hidden;
    }

    &:has(> :checked) {
        @apply bg-black text-white;
    }
}

.dark-form {
    @apply bg-dark;

    label {
        @apply text-white px-2.5 font-light;
    }
    input,
    textarea {
        @apply text-white border-dark-4e bg-dark-4e;
    }
    .btn-submit {
        @apply text-white border-dark-81 bg-dark-81;

        &:hover {
            @apply text-white border-black bg-black;
        }

        &:focus {
            @apply text-white border-black bg-black;
        }
    }
}



/* layout */
main {
    @apply max-w-screen-hd overflow-hidden mx-auto;
}
.container {
    @apply mobile:px-8;
}
.halfcontainer {
    @apply w-full desktop:max-w-145 desktop:mx-0;
}
.header-primary, h1 {
    @apply font-medium text-header-medium;
    @apply mobile:text-header-big;
}
.header-secondary {
    @apply font-medium text-xl;
    @apply mobile:text-2xl;
}
.sub-header {
    @apply font-light text-base mobile:text-lg;
}
strong {
    @apply font-medium;
}
b {
    @apply font-bold;
}
.main-top-padding {
    @apply pt-20 mobile:pt-28;
}

.pagination {
    @apply flex justify-center gap-2.5;

    li {
        @apply inline-flex;

        &.active {
            a {
                @apply bg-black text-white;
            }
        }
    }

    a {
        @apply inline-flex justify-center items-center h-14 min-w-14 p-2.5 rounded-lg text-black bg-secondary-e5;

        &.current {
            @apply bg-black text-white;
        }

        &:hover {
            @apply bg-black text-white;
        }
    }
}

#menu {
    @apply absolute -z-10 -top-380 left-0 flex flex-col gap-5 h-screen w-full transition-all bg-black;
    @apply desktop:relative desktop:z-0 desktop:ml-auto desktop:top-0 desktop:w-auto desktop:h-auto;
}
.menu-open {
    #menu {
        @apply top-0 pt-20 pb-5;
        @apply mobile:pt-28 mobile:pb-8;
        @apply desktop:pt-0 desktop:pb-0;
    }
}
#toggle-menu {
    @apply desktop:hidden;
}
#topmenu {
    @apply desktop:inline-flex desktop:items-center desktop:gap-5;

    li {
        @apply relative;

        a {
            @apply flex items-center gap-4 font-medium text-base py-2 text-white;
            @apply mobile:py-3;
            @apply desktop:inline-flex desktop:font-light desktop:text-sm;
            @apply 2xl:text-base;
        }
    }
    ul {
        @apply desktop:max-h-0 desktop:overflow-hidden desktop:pt-0 desktop:absolute desktop:top-full desktop:left-0 desktop:bg-black desktop:min-w-72 desktop:-ml-5;

        li {
            @apply pl-10 bg-menu-arr list-inside bg-no-repeat bg-[left_20px_center];

            a {
                @apply font-light;
            }
        }
    }

    li:hover > ul {
        @apply desktop:max-h-screen;
    }
}



/* breadcrumbs */
.breadcrumbs {
    @apply text-xs text-secondary-bc;

    li {
        @apply inline-block;

        &::before:not(:first-child) {
            @apply content-['/'] inline-block px-1;
        }

        a:hover {
            @apply text-white;
        }
    }
}
.breadcrumb {
    @apply text-xs text-secondary-bc;

    li {
        @apply inline-block;

        &::before:not(:first-child) {
            @apply content-['/'] inline-block px-1;
        }

        a:hover {
            @apply text-white;
        }
    }
}


/* swiper */
.dots {
    @apply flex justify-center items-center gap-3 mt-8;

    .swiper-pagination-bullet {
        @apply w-3 h-3 bg-secondary border border-transparent cursor-pointer !m-0 opacity-100;

        &:hover {
            @apply bg-secondary-d1 border-secondary-d1 opacity-100;
        }
    }

    .swiper-pagination-bullet-active {
        @apply bg-secondary-d1 border-secondary-d1 opacity-100;
    }

    &.swiper-pagination-lock {
        @apply mt-0;
    }
}
.swiper-home-img {
    @apply w-full h-full;

    .swiper-wrapper {
        @apply w-full h-full;

        .swiper-slide {
            @apply w-full h-full bg-cover bg-center;
        }
    }
}
#search-district {
    li {
        @apply flex justify-center items-center flex-1 h-24 min-w-[11rem];
        @apply laptop:items-end laptop:h-32;

        &.search-box__tab--current {
            label {
                @apply h-24 bg-secondary border-none shadow-lg;

                &::after {
                    @apply -bottom-5;
                }
            }
        }
    }

    label {
        @apply relative flex items-center justify-center w-full h-20 mb-0 p-5 rounded-lg cursor-pointer bg-zinc-100 transition-all duration-500 overflow-hidden shadow-lg;
        @apply peer-checked:h-24 peer-checked:bg-white peer-checked:shadow-lg;
        @apply hover:bg-gray-100;
        @apply laptop:h-28 laptop:rounded-b-none laptop:bg-secondary laptop:peer-checked:h-32 laptop:peer-checked:bg-secondary laptop:border-l laptop:border-r laptop:border-gray-300 laptop:shadow-none laptop:peer-checked:border-none laptop:peer-checked:shadow-none;

        &::after {
            @apply laptop:content-[''] absolute left-0 bottom-0 w-full h-5 bg-search_bg bg-repeat-x transition-all duration-500;
            @apply peer-checked:-bottom-5;
        }
    }

    img {
        @apply z-10 max-w-full max-h-16;
    }
}
.swiper-articles {
    .swiper-pagination-bullet {
        @apply bg-white;
    }
    .swiper-pagination-bullet-active {
        @apply bg-secondary-9a border-secondary-9a;
    }
}
.swiper-standards svg {
    @apply w-full;
}

.parameters-list {
    & > *:nth-child(even) {
        @apply bg-secondary-ee;
    }
    & > *:nth-child(odd) {
        @apply bg-secondary-f4;
    }
}



#headerSection3 {
    form {
        .form-group {
            @apply relative pb-2.5;

            input {
                @apply bg-secondary-ee text-black;
            }
            textarea {
                @apply bg-secondary-ee text-black;
            }
            .form-check-input {
                @apply absolute z-10 top-1.5;

                & + label {
                    @apply pl-8;
                }
            }
        }
        .actions {
            @apply flex justify-end w-full pt-5;

            [type="submit"] {
                @apply btn btn-submit w-full tablet:w-auto bg-[#818181];
            }
        }
    }
}

.apartment-view {
    &.active {
        @apply flex;
    }
    .btn {
        @apply p-4;
    }
}

.apartmentTrigger {
    @apply cursor-pointer;
}
.apartment-list-container {
    @apply relative w-full max-w-72 h-10 mb-4 hidden md:block;
}
.apartment-list {
    @apply absolute z-10 w-full text-base font-light rounded-xl max-w-full cursor-pointer bg-no-repeat bg-secondary-e8 border border-primary text-primary bg-list-arr min-h-10 overflow-hidden;

    background-position: right 1rem top 1rem;
    background-size: 12px;

    .current-apartment {
        @apply w-full py-1.5 pl-6 pr-10;
    }

    ul {
        @apply max-h-0 overflow-hidden transition-all;

        li {
            @apply block py-1.5 pl-6 pr-10;
            @apply hover:bg-secondary-d1 hover:text-black;

            &[data-sold="1"],
            &[data-reserved="1"] {
                @apply hidden;
            }
        }
    }

    &.active {
        ul {
            @apply max-h-180;
        }
    }
}

.floor-list-container {
    @apply relative w-full max-w-72 h-10 md:mb-4 hidden md:block;
}
.floor-list {
    @apply absolute z-10 w-full text-base font-light rounded-xl max-w-full cursor-pointer bg-no-repeat bg-secondary-e8 border border-primary text-primary bg-list-arr min-h-10 overflow-hidden;

    background-position: right 1rem top 1rem;
    background-size: 12px;

    .current-floor {
        @apply w-full py-1.5 pl-6 pr-10;
    }

    ul {
        @apply max-h-0 overflow-hidden transition-all;

        li {
            @apply block py-1.5 pl-6 pr-10;
            @apply hover:bg-secondary-d1 hover:text-black;

            &[data-sold="1"],
            &[data-reserved="1"] {
                @apply hidden;
            }
        }
    }

    &.active {
        ul {
            @apply max-h-180;
        }
    }
}

.mobileNav {
    @apply flex flex-wrap gap-4 w-full lg:hidden;

    & > div {
        @apply relative z-20 max-w-40 block;
    }

    .apartment-list,
    .floor-list {
        @apply bg-white;

        background-position: right .5rem top 1rem;
        background-size: 10px;

        ul li {
            @apply px-2 text-sm;

            a {
                @apply block;
            }
        }
    }

    .current-floor,
    .current-apartment {
        @apply pt-2 pl-2 pr-6 text-sm;
    }
}

.estateSvg {
    .areagroup {
        .area {
            @apply stroke-1 stroke-white fill-white transition-all duration-500;

            stroke-opacity: 1;
            fill-opacity: .2;
        }

        .point,
        .radar {
                @apply opacity-0;
        }

        &.clickable {
            .area {
                &:hover {
                    stroke-opacity: 1;
                    fill-opacity: .6;
                }
            }

            .point {
                fill: #fff;
                animation: pulse1 2s ease-in-out infinite;
            }

            .radar  {
                fill: #fff;;
                animation: pulse2 2s ease-in-out infinite;
            }

            @keyframes pulse1 {
                0% {
                    opacity: 0;
                    r: 4;
                }
                30% {
                    opacity: 1;
                    r: 6;
                }
                60% {
                    opacity: .1;
                    r: 8;
                }
                100% {
                    opacity: 0;
                    r: 8;
                }
            }

            @keyframes pulse2 {
                0% {
                    opacity: 0;
                    r: 6;
                }
                50% {
                    opacity: .3;
                }
                100% {
                    opacity: 0;
                    r: 16;
                }
            }
        }
    }
}

.tooltipClass {
    @apply absolute z-10 bg-white border border-white rounded-2xl p-4;

    .header {
        @apply font-medium text-xl text-black;
    }
    .info {
        @apply font-light text-xs text-secondary-79;
    }
    .apartments {
        @apply flex gap-3 mt-3 text-xs text-black;
    }
}

.stageSvg {
    .areagroup {
        .area {
            @apply stroke-2 stroke-white  transition-all duration-500;

            stroke-opacity: 0;
            fill-opacity: .2;

            &:hover {
                @apply fill-black;
            }
        }

        &.clickable {
            @apply no-underline;

            .area {
                &:hover {
                    @apply fill-accessibleFill;
                    stroke-opacity: 0;
                    fill-opacity: .6;
                }
            }

            &:focus {
                @apply outline-none border-none;
            }
        }

        &.sold {
            .area {
                @apply fill-soldFill;
            }

            &:hover {
                .area {
                    @apply fill-soldStroke;
                }
            }
        }
    }

    .buildings,
    .floors {
        @apply inline-flex bg-white;

        ul {
            @apply absolute left-0 top-full z-10 w-full -mt-1.5 bg-white border-b border-x border-gray-300 rounded-b-lg overflow-hidden;
        }

        li {
            @apply text-sm cursor-pointer px-3 py-2 hover:bg-gray-200;

            &.active {
                @apply bg-black text-white border-black;
            }
        }
    }
    .floors {
        li {
            @apply p-0;

            a {
                @apply block px-3 py-2 w-full;
            }
        }
    }
}

.florSvg {
    .areagroup {
        * {
            @apply transition-all duration-300;
        }
        .area {
            @apply  fill-accessibleFill stroke-secondary-70 stroke-[5px];

            fill-opacity: .5;
        }
        .circle {
            @apply opacity-0 fill-accessibleFill;
        }
        .contour {
            @apply opacity-0 fill-secondary-70;
        }
        .line {
            @apply fill-none stroke-secondary-70;
        }
        .dot {
            @apply fill-secondary-70;
        }
        .number {
            @apply opacity-0 fill-accessibleStroke;
        }

        &.sold {
            .area {
                @apply fill-soldFill;
            }
            .contour {
                @apply opacity-0;
            }
            .number {
                @apply opacity-0 fill-soldStroke;
            }
        }

        &.reserved {
            .area {
                @apply fill-reservedFill;
            }
            .contour {
                @apply opacity-0;
            }
            .number {
                @apply opacity-0 fill-reservedStroke;
            }
        }

        &:hover,
        &.active {
            .area {
                fill-opacity: .8;
            }
            .circle {
                @apply !opacity-80;
            }
            .contour {
                @apply !opacity-100;
            }
            .number {
                @apply !opacity-100;
            }

            &.sold {
                .circle {
                    @apply opacity-80 fill-soldFill;
                }
            }

            &.reserved {
                .circle {
                    @apply opacity-80 fill-reservedFill;
                }
            }
        }
    }
}

#bulding-popup {
    #apartments {
        @apply tablet:min-h-260px pb-4;
    }

    #main-content {
        @apply overflow-auto !h-auto;
    }

    .mapBox {
        @apply flex justify-center tablet:mt-4;

        @media (min-width: 640px) {
            @apply max-h-[700px];
        }

        .florSvg {
            @apply relative flex justify-center max-h-[700px];

            .svgBg {
                @apply block w-auto max-w-full max-h-full
            }
        }

        .svg-content {
            @apply absolute flex justify-center z-10 top-0 left-0 w-full h-full max-h-full;
        }

        div.maparea {
            @apply !bg-contain !h-auto;

            img.maparea {
                @apply w-auto !max-w-full !max-h-full !relative;
            }
        }
    }

    .pagination {
        @apply flex justify-start flex-wrap gap-2.5;

        .floor {
            @apply inline-flex justify-center items-center h-4 min-w-4 p-3 rounded-lg text-black bg-secondary-e5 text-xs;

            &.active {
                @apply bg-black !text-white;
            }

            &:not([disabled="disabled"]):hover {
                @apply bg-black !text-white;
            }

            &[disabled="disabled"] {
                @apply opacity-40;
            }

            @media (max-width: 460px) {
                &.page:not(.active) {
                    @apply hidden;
                }
                &.page.active + .page {
                     @apply inline-flex
                }
                &.page:has(+ .page.active) {
                    @apply inline-flex;
                }
            }
        }
    }

    .legend-list {
        @apply flex gap-4;

        li {
            @apply items-center text-micro mobile:text-xs tablet:text-sm laptop:text-base;
        }
    }


    #flatContainer {
        min-height: 340px;
    }
}

[data-label="Pow. Brutto"] {
    @apply hidden;
}

.app_cdi_stages .fancybox-container {
    .fancybox-slide {
        @apply !p-3;
    }
    .fancybox-content {
        min-height: 98%;
    }
    .fancybox-infobar,
    .fancybox-toolbar,
    .fancybox-button {
        @apply !hidden;
    }
}

.pagination-box {
    @apply pb-20;

    .pagination {
        @apply flex justify-center gap-2.5;

        button {
            @apply inline-flex justify-center items-center h-8 min-w-8 p-3 rounded-lg text-black bg-secondary-e5;

            &.active {
                @apply bg-black !text-white;
            }

            &:not([disabled="disabled"]):hover {
                @apply bg-black !text-white;
            }

            &[disabled="disabled"] {
                @apply opacity-20;
            }

            @media (max-width: 460px) {
                &.page:not(.active) {
                    @apply hidden;
                }
                &.page.active + .page {
                     @apply inline-flex
                }
                &.page:has(+ .page.active) {
                    @apply inline-flex;
                }
            }
        }
    }
}

.contactSection_slide1 h3 {
    line-height: 120%;
}

.section1_slide2 {
    &.swiper-slide-active {
        .group {
            @apply bg-black bg-opacity-70;
        }

        .ico1 {
            @apply hidden;
        }

        .ico2 {
            @apply block;
        }

        .title {
            @apply text-white;
        }

        .description {
            @apply max-h-96;
        }
    }
}


.swiper-numbers {
    .dots {
        @apply laptop:w-1/2 laptop:ml-1/2 laptop:justify-start laptop:pl-12;
    }

    .swiper-pagination-bullet {
        @apply bg-secondary-e8;

        &.swiper-pagination-bullet-active {
            @apply bg-secondary-a2 border-secondary-a2 ;
        }
    }
}

.swiper-profits {
    .swiper-pagination-bullet {
        @apply bg-white border-secondary-f4;

        &.swiper-pagination-bullet-active {
            @apply bg-secondary-a2 border-secondary-a2 ;
        }
    }
}

.swiper-agents {
    .swiper {
        @apply overflow-visible;
    }
}

.products {
    @apply py-5 lg:py-8 xl:py-12;

    .search-table {
        @apply pb-5;

        .row {
            @apply flex flex-col w-full mx-0 border-t border-dee2e6 flex-wrap py-3 md:flex-nowrap tablet:flex-row tablet:items-center tablet:py-0;

            &.header {
                @apply hidden bg-black text-sm tablet:flex;

                .cell {
                    @apply font-bold text-white tablet:p-2 desktop:px-4;
                }

                .link {
                    @apply hidden md:flex;
                }
            }

            &[data-sold="1"],
            &[data-reserved="1"] {
                @apply opacity-50;
            }
        }
        .cell {
            @apply flex justify-start items-center flex-1 gap-1 p-0.5 tablet:py-6 tablet:px-2 desktop:px-4;

            &.id {
                @apply tablet:flex-col tablet:items-start;
            }
        }

        .logo {
            @apply h-10 max-h-full;
        }
        .btn {
            @apply px-2.5 py-1 min-h-6 text-sm font-semibold;
        }
        .sortable {
            @apply cursor-n-resize;
        }
        .arr {
            @apply pl-1 -mr-3;
        }
        .link {
            flex: 0 0 100%;
            @apply gap-2 justify-end text-right w-full md:flex-[2];
        }
        .price {
            @apply mobile:justify-end mobile:text-right;
        }
        .compass {
            @apply hidden laptop:flex;
        }
        .label {
            @apply font-light text-gray-500 tablet:hidden;
        }
    }

    .controls {
        @apply relative flex items-center justify-center gap-5 p-2;
    }

    .items-per-page {
        @apply hidden absolute z-10 top-1 right-1.5 gap-3 bg-white md:flex;

        ul {
            @apply absolute left-0 bottom-full z-10 w-full -mb-1 bg-white border-t border-x border-gray-300 rounded-t-lg overflow-hidden;
        }

        li {
            @apply text-sm cursor-pointer px-4 py-2 hover:bg-gray-200;

            &.active {
                @apply bg-black text-white border-black;
            }
        }
    }

    .pagination-box {
        @apply pb-0;
    }
}

#cookiebar { @apply fixed left-0 right-0 bottom-16 z-50 flex flex-row p-3 bg-black text-white gap-8; }


.bg-grey70 { background-color: #707070; }
.bg-greyB9 { background-color: #B9B9B9; }
.bg-greyDE { background-color: #DEDEDE; }
.bg-greyD1 { background-color: #D1D1D1; }
.bg-greyEB { background-color: #EBEBEB; }
.bg-greyF0 { background-color: #F0F0F0; }
.bg-greyF5 { background-color: #F5F5F5; }
.bg-greyF7 { background-color: #F7F7F7; }
.bg-greyFA { background-color: #FAFAFA; }
.bg-greyE2 { background-color: #E2E2E2; }
.bg-greyE3 { background-color: #E3E3E3; }
.bg-greyEB { background-color: #EBEBEB; }
.bg-grey7E { background-color: #7E7E7E; }
.bg-greyD6 { background-color: #D6D6D6; }